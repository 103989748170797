module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-VVK5XL5QFQ"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrica/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"87758733","clickmap":true,"trackLinks":true,"accurateTrackBounce":true,"trackHash":true,"webvisor":true},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ЮЕ КСР","short_name":"ЮЕ КСР","start_url":"/","background_color":"#ED1C1C","theme_color":"#ED1C1C","display":"minimal-ui","icon":"src/images/favIcon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"37adf70cd6f484c05fae0fd4686b0f96"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Главная","crumbLabelUpdates":[{"pathname":"/about","crumbLabel":"О компании"},{"pathname":"/ourbrands","crumbLabel":"Производители"},{"pathname":"/services","crumbLabel":"Услуги"},{"pathname":"/info","crumbLabel":"Информация"},{"pathname":"/ourbrands/finder","crumbLabel":"Finder"},{"pathname":"/ourbrands/eaton","crumbLabel":"EATON"},{"pathname":"/ourbrands/festo","crumbLabel":"Festo"},{"pathname":"/ourbrands/raspberryPi","crumbLabel":"Raspberry Pi"},{"pathname":"/ourbrands/smc","crumbLabel":"SMC"},{"pathname":"/ourbrands/omron","crumbLabel":"Omron"},{"pathname":"/ourbrands/weidmuller","crumbLabel":"Weidmuller"},{"pathname":"/account","crumbLabel":"Аккаунт"},{"pathname":"/ourbrands/categories","crumbLabel":"Категории"},{"pathname":"/ourbrands/categories/integratedСircuits","crumbLabel":"Интегральные схемы и комплектующие к ним"},{"pathname":"/ourbrands/rockpi","crumbLabel":"ROCK Pi"},{"pathname":"/ourbrands/onpow","crumbLabel":"Onpow"},{"pathname":"/ourbrands/harting","crumbLabel":"Harting"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":87758733,"webvisor":true,"trackHash":true,"afterBody":false,"defer":false,"useCDN":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
