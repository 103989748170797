const palette = {
  mode: "light",
  common: {
    white: "#fff",
    black: "#000",
    pink: "#efb9b9",
    fuchsia: "#f206aa",
    orchid: "#de6be7",
    purpleMountain: "#9669a1",
    purpleHeart: "#6a4199",
    blue: "#1573fc",
    azure: "#3579bc",
    sapphire: "#1e5787",
    skobeloff: "#00687e",
    bubbles: "#e5f8fb",
    cyan: "#00bbdc",
    tiffany: "#01b1c2",
    persianGreen: "#00a5a1",
    electricBlue: "#56f7f0",
    turquoise: "#63e7ce",
    caribbeanGreen: "#00d286",
    seaGreen: "#23a248",
    deepGreen: "#05740b",
    bitterLemon: "#c4d600",
    bananaYellow: "#ffde29",
    goldenPoppy: "#fdc600",
    royalOrange: "#f69a40",
    heatWave: "#ff7b00",
    fireOpal: "#ea5f3c",
  },
  primary: {
    light: "#ff7474",
    main: "#ed1c1c",
    dark: "#be0909",
  },
  secondary: {
    light: "#9265c2",
    main: "#5f259f",
    dark: "#481283",
  },
  error: {
    light: "#f94a4a",
    main: "#ed1c1c",
    dark: "#be0909",
  },
  warning: {
    light: "#feefe0",
    main: "#f69a40",
    dark: "#ab5808",
  },
  info: {
    light: "#03d7ff",
    main: "#00bbdc",
    dark: "#008199",
  },
  success: {
    light: "#dcf0e2",
    main: "#23a248",
    dark: "#1a7a36",
  },
  gray: {
    50: "#f2f2f2",
    100: "#f8f8f8",
    200: "#efefef",
    300: "#e0e0e0",
    400: "#bcbcbc", // chip outlined ?? bdbdbd
    500: "#999999",
    600: "#757575",
    700: "#666666",
    800: "#424242",
    900: "#27282c", // footer
    A100: "#f4f4f4",
    A200: "#efefef",
    A400: "#bcbcbc",
    A700: "#666666",
  },
  text: {
    primary: "#2c2c2c",
    secondary: "#707070",
    disabled: "#999999",
  },
  divider: "#e3e3e3",
  background: {
    paper: "#ffffff",
    default: "#ffffff",
  },
  action: {
    active: "rgba(0, 0, 0, 0.54)",
    hover: "rgba(0, 0, 0, 0.04)",
    hoverOpacity: 0.04,
    selected: "rgba(0, 0, 0, 0.08)",
    selectedOpacity: 0.08,
    disabled: "rgba(0, 0, 0, 0.26)",
    disabledBackground: "rgba(0, 0, 0, 0.12)",
    disabledOpacity: 0.38,
    focus: "rgba(0, 0, 0, 0.12)",
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
  autonics: {
    primary: {
      main: "#fe5e01",
    },
  },
  finder: {
    primary: {
      main: "#019ee1",
      dark: "#0187c1",
    },
    secondary: {
      main: "#283756",
      light: "#324365",
    },
  },
  rpi: {
    black: "#24292f",
    primary: {
      light: "#df4372",
      main: "#cd2355",
      dark: "#a11b44",
    },
    secondary: {
      main: "#53ce63",
    },
    gradient: {
      main: "linear-gradient(180deg,#ececec,#f5f5f5 0%,#cc2455 0,#d75965)",
    },
  },
  omron: {
    black: "#14181c",
    primary: {
      dark: "#004f99",
      main: "#005fb9",
      light: "#306ccb",
    },
  },
  onpow: {
    primary: {
      main: "#af251a",
    },
    secondary: {
      main: "#e6e6e7",
    },
  },
  rockpi: {
    primary: {
      light: "#0add67",
      main: "#79bd28",
    },
    gradient: {
      main: "linear-gradient(180deg,#79bd28,#79bd28 0%,#79bd28 0,#79bd28)",
    },
  },
  eaton: {
    primary: {
      main: "#005eb8",
    },
  },
  smc: {
    primary: {
      main: "#0074be",
    },
  },
  weidmuller: {
    primary: {
      main: "#eb8c00",
    },
  },
  socomec: {
    primary: {
      dark: "#232C77",
      main: "#0070C0",
      light: "#d1daec",
    },
    gradient: "linear-gradient(324.68deg,#00519D 0%,rgba(0,81,157,0.8) 100%)",
  },
  harting: {
    primary: {
      main: "#fdc716",
    },
  },
  aignep: {
    black: "#414042",
    gray: {
      main: "#d8d8d8",
    },
    primary: {
      main: "#ec2122",
    },
  },
  asus: {
    primary: {
      main: "#000B8C",
    },
  },
  orangePi: {
    primary: {
      main: "#f4a524",
    },
    secondary: {
      main: "#f7f7f7",
    },
    gradient: "linear-gradient(324.68deg,#efbd0d 0%,#da642f 100%)",
  },
  nvidia: {
    primary: {
      main: "#76b900",
    },
    secondary: {
      main: "#666",
    },
  },
  degson: {
    primary: {
      main: "#01cb00",
    },
  },
  arduino: {
    primary: {
      main: "#008184",
    },
    secondary: {
      main: "#666",
    },
  },
  khadas: {
    background: "#200e4c",
  },
  klemsan: {
    primary: {
      main: "#f18700",
    },
  },
  hongfa: {
    primary: {
      light: "#00a0e9",
      main: "#00479d",
    },
    gradient:
      "linear-gradient(0deg, rgba(0,163,230,1) 0%, rgba(15,66,119,1) 100%)",
  },
  shenmao: {
    primary: {
      main: "#3f41a6",
      dark: "#322a71",
    },
  },
  autonics: {
    primary: {
      light: "#00a0e9",
      main: "#fe5e01",
      orangeLight: "rgb(251,196,52)",
      cyanLight: "rgb(148,208,198)",
      blueLight: "rgb(132,208,244)",
      purpleight: "rgb(193,139,188)",
      greenLight: "rgb(159,203,80,1)",
    },
    gray: "#f5f5f5",
    orangeGradient:
      "linear-gradient(0deg, rgba(251,196,52,1) 0%, rgba(221,79,3,1) 100%)",
    cyanGradient:
      "linear-gradient(0deg, rgba(148,208,198,1) 0%, rgba(0,178,179,1) 100%)",
    blueGradient:
      "linear-gradient(0deg, rgba(132,208,244,1) 0%, rgba(0,89,169,1) 100%)",
    purpleGradient:
      "linear-gradient(0deg, rgba(193,139,188,1) 0%, rgba(110,53,132,1) 100%)",
    greenGradient:
      "linear-gradient(0deg, rgba(159,203,80,1) 0%, rgba(38,70,69,1) 100%)",
  },
}

export default palette
