import { createTheme } from "@mui/material/styles"

import breakpoints from "./breakpoints"
import components from "./components"
import mixins from "./mixins"
import palette from "./palette"
import shadows from "./shadows"
import shape from "./shape"
import spacing from "./spacing"
import transitions from "./transitions"
import typography from "./typography"
import zIndex from "./zIndex"

const theme = createTheme({
  breakpoints,
  components,
  mixins,
  palette,
  shadows,
  shape,
  spacing,
  transitions,
  typography,
  zIndex,
})

export default theme
