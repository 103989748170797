exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalogues-js": () => import("./../../../src/pages/catalogues.js" /* webpackChunkName: "component---src-pages-catalogues-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-privacy-js": () => import("./../../../src/pages/info/privacy.js" /* webpackChunkName: "component---src-pages-info-privacy-js" */),
  "component---src-pages-info-website-terms-js": () => import("./../../../src/pages/info/websiteTerms.js" /* webpackChunkName: "component---src-pages-info-website-terms-js" */),
  "component---src-pages-ny-index-js": () => import("./../../../src/pages/ny/index.js" /* webpackChunkName: "component---src-pages-ny-index-js" */),
  "component---src-pages-ourbrands-aignep-index-js": () => import("./../../../src/pages/ourbrands/aignep/index.js" /* webpackChunkName: "component---src-pages-ourbrands-aignep-index-js" */),
  "component---src-pages-ourbrands-autonics-autonics-sensors-js": () => import("./../../../src/pages/ourbrands/autonics/autonicsSensors.js" /* webpackChunkName: "component---src-pages-ourbrands-autonics-autonics-sensors-js" */),
  "component---src-pages-ourbrands-autonics-index-js": () => import("./../../../src/pages/ourbrands/autonics/index.js" /* webpackChunkName: "component---src-pages-ourbrands-autonics-index-js" */),
  "component---src-pages-ourbrands-categories-component-analogues-js": () => import("./../../../src/pages/ourbrands/categories/componentAnalogues.js" /* webpackChunkName: "component---src-pages-ourbrands-categories-component-analogues-js" */),
  "component---src-pages-ourbrands-categories-integrated-circuits-js": () => import("./../../../src/pages/ourbrands/categories/integratedCircuits.js" /* webpackChunkName: "component---src-pages-ourbrands-categories-integrated-circuits-js" */),
  "component---src-pages-ourbrands-categories-semiconductors-js": () => import("./../../../src/pages/ourbrands/categories/semiconductors.js" /* webpackChunkName: "component---src-pages-ourbrands-categories-semiconductors-js" */),
  "component---src-pages-ourbrands-categories-suppliers-js": () => import("./../../../src/pages/ourbrands/categories/suppliers.js" /* webpackChunkName: "component---src-pages-ourbrands-categories-suppliers-js" */),
  "component---src-pages-ourbrands-connfly-index-js": () => import("./../../../src/pages/ourbrands/connfly/index.js" /* webpackChunkName: "component---src-pages-ourbrands-connfly-index-js" */),
  "component---src-pages-ourbrands-degson-index-js": () => import("./../../../src/pages/ourbrands/degson/index.js" /* webpackChunkName: "component---src-pages-ourbrands-degson-index-js" */),
  "component---src-pages-ourbrands-degson-terminal-blocks-js": () => import("./../../../src/pages/ourbrands/degson/terminalBlocks.js" /* webpackChunkName: "component---src-pages-ourbrands-degson-terminal-blocks-js" */),
  "component---src-pages-ourbrands-eaton-eaton-switches-js": () => import("./../../../src/pages/ourbrands/eaton/eatonSwitches.js" /* webpackChunkName: "component---src-pages-ourbrands-eaton-eaton-switches-js" */),
  "component---src-pages-ourbrands-festo-festo-control-valves-js": () => import("./../../../src/pages/ourbrands/festo/festoControlValves.js" /* webpackChunkName: "component---src-pages-ourbrands-festo-festo-control-valves-js" */),
  "component---src-pages-ourbrands-finder-index-js": () => import("./../../../src/pages/ourbrands/finder/index.js" /* webpackChunkName: "component---src-pages-ourbrands-finder-index-js" */),
  "component---src-pages-ourbrands-finder-relays-and-timers-js": () => import("./../../../src/pages/ourbrands/finder/relaysAndTimers.js" /* webpackChunkName: "component---src-pages-ourbrands-finder-relays-and-timers-js" */),
  "component---src-pages-ourbrands-fire-fly-index-js": () => import("./../../../src/pages/ourbrands/fireFly/index.js" /* webpackChunkName: "component---src-pages-ourbrands-fire-fly-index-js" */),
  "component---src-pages-ourbrands-harting-harting-js": () => import("./../../../src/pages/ourbrands/harting/harting.js" /* webpackChunkName: "component---src-pages-ourbrands-harting-harting-js" */),
  "component---src-pages-ourbrands-harting-harting-pcb-js": () => import("./../../../src/pages/ourbrands/harting/hartingPCB.js" /* webpackChunkName: "component---src-pages-ourbrands-harting-harting-pcb-js" */),
  "component---src-pages-ourbrands-hongfa-index-js": () => import("./../../../src/pages/ourbrands/hongfa/index.js" /* webpackChunkName: "component---src-pages-ourbrands-hongfa-index-js" */),
  "component---src-pages-ourbrands-js": () => import("./../../../src/pages/ourbrands.js" /* webpackChunkName: "component---src-pages-ourbrands-js" */),
  "component---src-pages-ourbrands-khadas-index-js": () => import("./../../../src/pages/ourbrands/khadas/index.js" /* webpackChunkName: "component---src-pages-ourbrands-khadas-index-js" */),
  "component---src-pages-ourbrands-klemsan-index-js": () => import("./../../../src/pages/ourbrands/klemsan/index.js" /* webpackChunkName: "component---src-pages-ourbrands-klemsan-index-js" */),
  "component---src-pages-ourbrands-omron-omron-kit-js": () => import("./../../../src/pages/ourbrands/omron/omronKit.js" /* webpackChunkName: "component---src-pages-ourbrands-omron-omron-kit-js" */),
  "component---src-pages-ourbrands-onpow-onpow-js": () => import("./../../../src/pages/ourbrands/onpow/onpow.js" /* webpackChunkName: "component---src-pages-ourbrands-onpow-onpow-js" */),
  "component---src-pages-ourbrands-oswell-index-js": () => import("./../../../src/pages/ourbrands/oswell/index.js" /* webpackChunkName: "component---src-pages-ourbrands-oswell-index-js" */),
  "component---src-pages-ourbrands-radxa-radxa-js": () => import("./../../../src/pages/ourbrands/radxa/radxa.js" /* webpackChunkName: "component---src-pages-ourbrands-radxa-radxa-js" */),
  "component---src-pages-ourbrands-raspberry-pi-deleted-raspberry-pi-kit-js": () => import("./../../../src/pages/ourbrands/raspberryPiDeleted/raspberryPiKit.js" /* webpackChunkName: "component---src-pages-ourbrands-raspberry-pi-deleted-raspberry-pi-kit-js" */),
  "component---src-pages-ourbrands-rockpi-rockpi-addons-js": () => import("./../../../src/pages/ourbrands/rockpi/rockpiAddons.js" /* webpackChunkName: "component---src-pages-ourbrands-rockpi-rockpi-addons-js" */),
  "component---src-pages-ourbrands-rockpi-rockpi-js": () => import("./../../../src/pages/ourbrands/rockpi/rockpi.js" /* webpackChunkName: "component---src-pages-ourbrands-rockpi-rockpi-js" */),
  "component---src-pages-ourbrands-shenmao-shenmao-js": () => import("./../../../src/pages/ourbrands/shenmao/shenmao.js" /* webpackChunkName: "component---src-pages-ourbrands-shenmao-shenmao-js" */),
  "component---src-pages-ourbrands-single-board-index-js": () => import("./../../../src/pages/ourbrands/singleBoard/index.js" /* webpackChunkName: "component---src-pages-ourbrands-single-board-index-js" */),
  "component---src-pages-ourbrands-single-board-rock-pi-fire-fly-index-js": () => import("./../../../src/pages/ourbrands/singleBoardRockPiFireFly/index.js" /* webpackChunkName: "component---src-pages-ourbrands-single-board-rock-pi-fire-fly-index-js" */),
  "component---src-pages-ourbrands-smc-smc-pneumo-js": () => import("./../../../src/pages/ourbrands/smc/smcPneumo.js" /* webpackChunkName: "component---src-pages-ourbrands-smc-smc-pneumo-js" */),
  "component---src-pages-ourbrands-socomec-index-js": () => import("./../../../src/pages/ourbrands/socomec/index.js" /* webpackChunkName: "component---src-pages-ourbrands-socomec-index-js" */),
  "component---src-pages-ourbrands-umv-index-js": () => import("./../../../src/pages/ourbrands/umv/index.js" /* webpackChunkName: "component---src-pages-ourbrands-umv-index-js" */),
  "component---src-pages-ourbrands-weidmuller-weidmuller-powersupply-js": () => import("./../../../src/pages/ourbrands/weidmuller/weidmullerPowersupply.js" /* webpackChunkName: "component---src-pages-ourbrands-weidmuller-weidmuller-powersupply-js" */),
  "component---src-pages-services-about-us-js": () => import("./../../../src/pages/services/aboutUs.js" /* webpackChunkName: "component---src-pages-services-about-us-js" */),
  "component---src-pages-services-delivery-js": () => import("./../../../src/pages/services/delivery.js" /* webpackChunkName: "component---src-pages-services-delivery-js" */),
  "component---src-pages-services-exportcontrol-js": () => import("./../../../src/pages/services/exportcontrol.js" /* webpackChunkName: "component---src-pages-services-exportcontrol-js" */),
  "component---src-pages-services-ordering-js": () => import("./../../../src/pages/services/ordering.js" /* webpackChunkName: "component---src-pages-services-ordering-js" */),
  "component---src-pages-services-products-js": () => import("./../../../src/pages/services/products.js" /* webpackChunkName: "component---src-pages-services-products-js" */),
  "component---src-pages-services-requisites-js": () => import("./../../../src/pages/services/requisites.js" /* webpackChunkName: "component---src-pages-services-requisites-js" */),
  "component---src-pages-services-returns-js": () => import("./../../../src/pages/services/returns.js" /* webpackChunkName: "component---src-pages-services-returns-js" */),
  "component---src-pages-services-rscomponents-js": () => import("./../../../src/pages/services/rscomponents.js" /* webpackChunkName: "component---src-pages-services-rscomponents-js" */),
  "component---src-pages-services-technical-help-js": () => import("./../../../src/pages/services/technicalHelp.js" /* webpackChunkName: "component---src-pages-services-technical-help-js" */),
  "component---src-pages-services-warehouse-js": () => import("./../../../src/pages/services/warehouse.js" /* webpackChunkName: "component---src-pages-services-warehouse-js" */)
}

