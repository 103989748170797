const breakpoints = {
  values: {
    xs: 0,
    sm: 768,
    md: 1024,
    lg: 1200,
    xl: 1536,
  },
}

export default breakpoints
